import AgGridCellTimestampedValue from '../../../extensions/AgGridExtensions/AgGridCells/AgGridCellTimestampedValue'
import AgGridColumnTooltip from '../../../extensions/AgGridExtensions/AgGridColumns/AgGridColumnTooltip'
import { addLinkColumn } from '../../../extensions/AgGridExtensions/AgGridColumns/AgGridCustomColumns'
import UrlNavigationService from '../../../services/url-service'

const getStockUrl = (p: any) => {
  const id = Number(p.data?.companyId)
  return UrlNavigationService.stockDetails(id)
}

const getLinkName = (p: any) => {
  return p?.data?.companyName
}

const renderRelevancyTooltip = (params: any) => {
  return <div>
    <p>Total points = {params.data.relevancy?.total}</p>
    <hr/>
    <p>POINTS</p>
    <p>From dividends: {params.data.relevancy?.pointsForDividends}</p>
    <p>From recommendations {params.data.relevancy?.pointsForRecommendations}</p>
  </div>
}

export const getAssistantTableColumns = () => {
  const columns = [
    addLinkColumn(
      'companyName',
      '',
      (p) => getStockUrl(p),
      true,
      (p) => getLinkName(p)
    ),
    {
      field: 'relevancy',
      sortable: true,
      valueGetter: (params: any) => {
        return params.data.relevancy?.total
      },
      tooltipValueGetter: (param: any) => renderRelevancyTooltip(param),
      headerTooltip: 'Points details',
      tooltipComponent: AgGridColumnTooltip
    },
    {
      field: 'currentQuotation',
      sortable: true,
      valueGetter: (params: any) => {
        return params.data.inputModel.lastQuotation?.value
      }
    },
    {
      field: 'priceBookValue',
      valueGetter: (params: any) => {
        return params.data.inputModel.priceBookValue?.value
      },
      autoHeight: true,
      cellRenderer: AgGridCellTimestampedValue,
      cellRendererParams: {
        getDataValue: (params: any) => params.data.inputModel.priceBookValue
      }
    },
    {
      field: 'priceEarningRating',
      valueGetter: (params: any) => {
        return params.data.inputModel.priceEarningRating?.value
      },
      autoHeight: true,
      cellRenderer: AgGridCellTimestampedValue,
      cellRendererParams: {
        getDataValue: (params: any) => params.data.inputModel.priceEarningRating
      }
    },
    {
      field: 'rating',
      valueGetter: (params: any) => {
        return params.data.inputModel.rating?.value
      },
      autoHeight: true,
      cellRenderer: AgGridCellTimestampedValue,
      cellRendererParams: {
        getDataValue: (params: any) => params.data.inputModel.rating
      }
    },
    {
      field: 'recommendationCount',
      valueGetter: (params: any) => {
        return params.data.inputModel.recommendations?.length
      }
    },
    {
      field: 'dividentCount',
      valueGetter: (params: any) => {
        return params.data.inputModel.dividend?.yearWithContinuePaidDividends
      }
    }
  ]

  return columns
}
