/* tslint:disable */
/* eslint-disable */
/**
 * JPCode Gielda API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RelevancyPoints
 */
export interface RelevancyPoints {
    /**
     * 
     * @type {number}
     * @memberof RelevancyPoints
     */
    readonly total?: number;
    /**
     * 
     * @type {number}
     * @memberof RelevancyPoints
     */
    pointsForDividends?: number;
    /**
     * 
     * @type {number}
     * @memberof RelevancyPoints
     */
    pointsForRecommendations?: number;
}

export function RelevancyPointsFromJSON(json: any): RelevancyPoints {
    return RelevancyPointsFromJSONTyped(json, false);
}

export function RelevancyPointsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RelevancyPoints {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': !exists(json, 'total') ? undefined : json['total'],
        'pointsForDividends': !exists(json, 'pointsForDividends') ? undefined : json['pointsForDividends'],
        'pointsForRecommendations': !exists(json, 'pointsForRecommendations') ? undefined : json['pointsForRecommendations'],
    };
}

export function RelevancyPointsToJSON(value?: RelevancyPoints | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pointsForDividends': value.pointsForDividends,
        'pointsForRecommendations': value.pointsForRecommendations,
    };
}

